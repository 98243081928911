import React from 'react'
import ScoreElement from "./ScoreElement";
import {getModusById} from "../constants/GameModusComponent";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export function PlayerToGoMultiplayerArea({
                                              player,
                                              isSetModus,
                                              isLegBeginner,
                                              active,
                                              togo,
                                              gewonneneSets,
                                              gewonneneLegs
                                          }) {

    let backgroundColorScore = active ? "#ac1c27" : "#022836";
    let backgroundColorKasten = active ? "#022836" : "#022836";

    return <div style={{
            textAlign: "center",
            backgroundColor: backgroundColorKasten,
            color: "white",
        }}>
            <div className="grid" style={{margin: "auto"}}>
                <div className="col" style={{
                    border: "2px solid white",
                    borderRadius: 20,
                    margin: 5,
                    fontSize: 100,
                    fontWeight: "revert",
                    color: active ? "black" : "white",
                    backgroundColor: backgroundColorScore,
                }}>
                    <div style={{fontSize:30}}>{player.name}</div>
                    <div style={{fontSize: 30}}>
                        {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner"
                                                                       style={{marginRight: 10, height: 30, width: 30}}/>}
                        {isSetModus && "Sets: " + gewonneneSets + " - "}Legs: {gewonneneLegs}
                        {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner"
                                               style={{marginLeft: 10, height: 30, width: 30}}/>}
                    </div>
                    <div style={active ? {color: "black"} : {}}>{togo}</div>
                </div>
            </div>
        </div>
}

export function PlayerStatistikMultiplayerArea({
                                              player,
                                              isLegBeginner,
                                              active, average, averageLeg, averageFirst9, count100Plus, count140Plus, count171er, count180er, bestLeg, worstLeg, highOut, showSettings
                                          }) {
    let backgroundColorScore = active ? "#ac1c27" : "#022836";

    return <div className="grid" style={{width: "100%", margin: 0, marginTop: 0, padding: 0}}>
        <div className="col" style={{
            marginRight: 3,
            backgroundColor: backgroundColorScore,
            color: "white",
            fontSize: 40,
        }}>
            <div style={{fontSize: 50}}>{player.name}
                {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner"
                                       style={{marginLeft: 10, height: 40, width: 40}}/>}
                <span style={{fontSize: 20, marginLeft:10}}>
                    {showSettings.showAverage && "Avg: " + average + " | "}
                    {showSettings.showAverageFirst9 && "Avg first 9: " + averageFirst9 + " | "}
                    {showSettings.showAverageLeg && "Leg Avg.: " + averageLeg + " | "}
                    {showSettings.show100Plus && "100+: " + count100Plus + " | "}
                    {showSettings.show140Plus && "140+: " + count140Plus + " | "}
                    {showSettings.show171 && "171: " + count171er + " | "}
                    {showSettings.show180 && "171: " + count180er + " | "}
                    {showSettings.showBestLeg && "Best: " + bestLeg + " | "}
                    {showSettings.showWorstLeg && "Worst: " + worstLeg + " | "}
                    {showSettings.showHighOut && "High Out: " + highOut}
                </span>
            </div>
        </div>
    </div>
}

export function PlayerSingleArea(props) {
    const player = props.player;

    let fontColorScore = "white";
    let backgroundColorScore = "#022836";
    let backgroundColorKasten = "#022836";
    if( props.canCheck && props.active) {
        backgroundColorScore = "green";
        backgroundColorKasten = "#022836";
    } else if( props.active) {
        backgroundColorScore = "#ac1c27";
        backgroundColorKasten = "#022836";
    } else if( props.canCheck) {
        backgroundColorScore = "#064d6b";
        fontColorScore = "#46FF33";
    }

    return <div className="col" key={"key_togo_" + player.id} style={{
        textAlign: "center",
        marginRight: 3,
        backgroundColor: backgroundColorKasten,
        color: "white",
    }}>
        <div className="grid" style={{margin: "auto"}}>
            <div className="col" style={{
                border: props.isLegBeginner ? "10px solid #7a1c23" : "10px solid #022836",
                borderRadius: 20,
                margin: 5,
                fontWeight: "revert",
                backgroundColor: backgroundColorScore,
            }}>
                <span style={{color: fontColorScore, fontSize: props.fontSize}}>{props.togo}</span>
                {props.onTheWayToNineDarter && <span style={{paddingLeft:20, paddingRight:20, color: "yellow", fontSize: props.fontSize}}>-9-</span>}
            </div>
        </div>
    </div>
}

export function ScoreAreaMulitplayerBig({player, scores, anzScores, startpunkte, selectedScore, selectedValue}) {
    let lineStyle = {
        verticalAlign: "middle",
        textAlign: "center",
        fontSize: 40,
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#064d6b",
        color: "white",
    };
    let rows = [];

    rows.push(<div key={"score_startpunkte_" + player.playernummer} className="grid" style={lineStyle}>
        <div className="col-fixed" style={{width: 90}}></div>
        <div className="col"></div>
        <div className="col">{startpunkte}</div>
    </div>);

    for (let i = 0; i < anzScores; i++) {
        let score = scores[i];
        let isLastRow = i === anzScores - 1;
        let modulo = i % 2;
        let currentStyle = modulo === 1 ? {...lineStyle, backgroundColor: "#064d6b", height: isLastRow ? 100 : 70} : {
            ...lineStyle,
            backgroundColor: "#022836", height: isLastRow ? 100 : 70
        };
        rows.push(<div key={"score_" + i + "_player_" + player.playernummer} className="grid" style={currentStyle}>
            <div className="col-fixed"
                 style={{
                     padding: 0,
                     width: 90,
                     margin: "auto",
                     verticalAlign: "middle",
                     borderRight: "1px solid gray"
                 }}>{i + 1}</div>
            {score !== undefined ?
                <ScoreElement isTablet={false} scorecounter={score.counter} selectedScore={selectedScore}
                              selectedValue={selectedValue} value={score.value}
                              invalid={score.invalid !== undefined ? score.invalid : false}/> :
                <div></div>}
            <div className="col">{score !== undefined ? score.togo : ""}</div>
        </div>)
    }

    rows.push(<div key={"score_header_" + player.playernummer} className="grid" style={{margin:0, backgroundColor: "white", color:"black", verticalAlign: "middle", textAlign: "center", fontSize: 25}}>
        <div className="col-fixed" style={{width: 90}}></div>
        <div className="col">scored</div>
        <div className="col">to go</div>
    </div>);

    let rowsGedreht = rows.reverse();
    return <div className="grid"
                style={{
                    margin: 0,
                    padding: 0,
                }}>
        <div className="col" style={{margin: 0, padding: 0}}>
            {rowsGedreht}
        </div>
    </div>
}

export function ScoreAreaDesktopTwoPlayersBig({scoresPlayer1, scoresPlayer2, anzScores, startpunkte, selectedScore, selectedValue, fontSize, setSelectedScore}) {
    let lineStyle = {
        verticalAlign: "middle",
        textAlign: "center",
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#064d6b",
        color: "white",
        fontSize: fontSize,
    };
    let anzahlWuerfeColWidth = 80;
    if( fontSize >= 110) {
        anzahlWuerfeColWidth = 150;
    } else if( fontSize >= 100) {
        anzahlWuerfeColWidth = 130;
    } else if( fontSize >= 80) {
        anzahlWuerfeColWidth = 115;
    } else if( fontSize >= 60) {
        anzahlWuerfeColWidth = 100;
    }
    let rows = [];
    rows.push(<div key={"score_beginn"} className="grid" style={lineStyle}>
        <div className="col"></div>
        <div className="col">{startpunkte}</div>
        <div className="col-fixed" style={{width:80}}></div>
        <div className="col"></div>
        <div className="col">{startpunkte}</div>
    </div>);
    for (let i = 0; i < anzScores; i++) {
        let modulo = i % 2;
        let currentStyle = modulo === 1 ? {...lineStyle, backgroundColor: "#064d6b"} : {
            ...lineStyle,
            backgroundColor: "#022836"
        };
        let scorePlayer1 = scoresPlayer1[i];
        let scorePlayer2 = scoresPlayer2[i];

        rows.push(<div key={"score_" + i} className="grid" style={currentStyle}>
            {scorePlayer1 !== undefined ?
                <ScoreElement isTablet={false} scorecounter={scorePlayer1.counter} selectedScore={selectedScore}
                              selectedValue={selectedValue} value={scorePlayer1.value}
                              invalid={scorePlayer1.invalid !== undefined ? scorePlayer1.invalid : false} fontSize={fontSize} setSelectedScore={setSelectedScore}/> :
                <div className="col" style={{paddingTop:5, paddingBottom:5}}></div>}
            <div className="col" style={{paddingTop:5, paddingBottom:5}}>{scorePlayer1 !== undefined && scorePlayer1.togo}</div>
            <div className="col-fixed"
                 style={{
                     paddingTop: 5,
                     paddingBottom: 5,
                     width: anzahlWuerfeColWidth,
                     margin: "auto",
                     verticalAlign: "middle",
                     borderLeft: "1px solid gray",
                     borderRight: "1px solid gray"
                 }}>{(i + 1) * 3}</div>
            {scorePlayer2 !== undefined ?
                <ScoreElement isTablet={false} scorecounter={scorePlayer2.counter} selectedScore={selectedScore}
                              selectedValue={selectedValue} value={scorePlayer2.value}
                              invalid={scorePlayer2.invalid !== undefined ? scorePlayer2.invalid : false} fontSize={fontSize} setSelectedScore={setSelectedScore}/> :
                <div className="col" style={{paddingTop:5, paddingBottom:5}}></div>}
            <div className="col" style={{paddingTop:5, paddingBottom:5}}>{scorePlayer2 !== undefined ? scorePlayer2.togo : ""}</div>
        </div>)
    }
    let rowsGedreht = rows.reverse();
    return <div className="grid"
                style={{
                    margin: 0,
                    padding: 0,
                }}>
        <div className="col" style={{margin: 0, padding: 0}}>
            {rowsGedreht}
        </div>
    </div>
}

export function ScoreAreaSmartphone({scoresPlayer1, scoresPlayer2, anzScores, startpunkte, selectedScore, selectedValue, setSelectedScore}) {
    let lineStyle = {
        verticalAlign: "middle",
        textAlign: "center",
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#064d6b",
        color: "white",
        fontSize: 18,
        height:40,
    };
    let rows = [];
    rows.push(<div className="grid" key={"score_beginn"} style={lineStyle}>
        <div className="col"></div>
        <div className="col">{startpunkte}</div>
        <div className="col-fixed" style={{width:40}}></div>
        <div className="col">{startpunkte}</div>
        <div className="col"></div>
    </div>);
    for (let i = 0; i < anzScores; i++) {
        let modulo = i % 2;
        let currentStyle = modulo === 1 ? {...lineStyle, backgroundColor: "#064d6b"} : {
            ...lineStyle,
            backgroundColor: "#022836"
        };
        let scorePlayer1 = scoresPlayer1[i];
        let scorePlayer2 = scoresPlayer2[i];

        rows.push(<div key={"score_" + i} className="grid" style={currentStyle}>
            {scorePlayer1 !== undefined ?
                <ScoreElement isTablet={false} scorecounter={scorePlayer1.counter} selectedScore={selectedScore}
                              selectedValue={selectedValue} value={scorePlayer1.value}
                              invalid={scorePlayer1.invalid !== undefined ? scorePlayer1.invalid : false} setSelectedScore={setSelectedScore}/> :
                <div className="col"></div>}
            <div className={"col"}>{scorePlayer1 !== undefined && scorePlayer1.togo}</div>
            <div className={"col-fixed"}
                 style={{
                     padding: 0,
                     width: 40,
                     margin: "auto",
                     verticalAlign: "middle",
                     borderLeft: "1px solid gray",
                     borderRight: "1px solid gray"
                 }}>{(i + 1)*3}</div>
            {scorePlayer2 !== undefined ?
                <ScoreElement isTablet={false} scorecounter={scorePlayer2.counter} selectedScore={selectedScore}
                              selectedValue={selectedValue} value={scorePlayer2.value}
                              invalid={scorePlayer2.invalid !== undefined ? scorePlayer2.invalid : false} setSelectedScore={setSelectedScore}/> :
                <div className="col"></div>}
            <div className={"col"}>{scorePlayer2 !== undefined ? scorePlayer2.togo : ""}</div>
        </div>)
    }
    let rowsGedreht = rows.reverse();
    return <div className="grid"
                style={{
                    margin: 0,
                    padding: 0,
                }}>
        <div className="col" style={{margin: 0, padding: 0}}>
            {rowsGedreht}
        </div>
    </div>
}

export function PlayerTableArea({name, mannschaftName, isSetModus, gewonneneSets, gewonneneLegs, playerCount, fontSize, showCheckOut, checkOut}) {

    let wert;
    if(playerCount > 2) {
        wert = <div>{isSetModus && "Sets: " + gewonneneSets + "/ "}Legs: {gewonneneLegs}</div>;
    } else {
        wert = <div>{isSetModus && "Sets: " + gewonneneSets + "/ "}Legs: {gewonneneLegs}</div>;
    }
    return <div className="col-fixed" style={{width:"50%"}}>
        {mannschaftName !== null && mannschaftName !== undefined && mannschaftName !== "" &&
            <div style={{
                display: "block",
                textAlign: "center",
                fontSize: fontSize,
                backgroundColor: "#ac1c27",
                color: "white",
                whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
            }}>{mannschaftName}</div>
        }
        <div style={{
            display: "block",
            textAlign: "center",
            fontSize: fontSize,
            backgroundColor: "#ac1c27",
            color: "white",
            whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
        }}>{name}</div>
        <div style={{
            textAlign: "center",
            fontSize: (fontSize - 10),
            backgroundColor: "#ac1c27",
            color: "white",
        }}>{wert}</div>
        {showCheckOut && checkOut !== null && checkOut !== "" && <div style={{
            textAlign: "center",
            fontSize: (fontSize - 6),
            backgroundColor: "#ac1c27",
            color: "white",}}>Checkout: {checkOut}</div>
        }
    </div>
}

export function PCSmallWindowPlayerArea(props) {

    const area = (label, value) => {
        return <span>{label}: {value} / </span>;
    }

    return <div style={{padding:5, textAlign: props.align, backgroundColor:"#ac1c27", color:"white", fontSize: props.fontSizePlayer}}>
        {props.name}
        <span style={{marginLeft:10, marginRight:10}}>/</span>
        {props.isSetModus && "Sets: " + props.gewonneneSets(this.state.game, 0) + " - "}Legs: {props.gewonneneLegs}
        {(props.showCheckout && props.checkout !== undefined && props.checkout !== "") &&
            <div style={{fontSize: props.fontSizePlayer - 2}}>Checkout: {props.checkout}</div>
        }
        <div style={{fontSize: props.fontSizeStatstik}}>
            {props.showSettings.showAverage && area("Average", props.average)}
            {props.showSettings.showAverageFirst9 && area("First 9", props.averageFirst9)}
            {props.showSettings.showAverageLeg && area("Leg Avg.", props.averageLeg)}
            {props.showSettings.show100Plus && area("100+", props.count100Plus)}
            {props.showSettings.show140Plus && area("140+", props.count140Plus)}
            {props.showSettings.show171 && area("171", props.count171er)}
            {props.showSettings.show180 && area("180", props.count180er)}
            {props.showSettings.showBestLeg && area("Best", props.bestLeg)}
            {props.showSettings.showWorstLeg && area("Worst", props.worstLeg)}
            {props.showSettings.showHighOut && area("High Out", props.highOut)}
        </div>
    </div>
}

export function StatistikArea(props) {

    const kachel = (label, value) => {
        if( props.statisticOrientation === "liste" ) {
            return <div className="grid" style={{width:"100%", padding:0, paddingTop:10, fontSize:props.fontSizeStatistik}}>
                <div className="col-fixed" style={{width:"60%", padding: 0, paddingLeft:10}}>{label}:</div>
                <div className="col-fixed" style={{width:"40%", padding: 0 }}>{value}</div>
            </div>
        } else {
            return <div style={{textAlign: "center", padding:40, borderBottom:"1px solid gray", fontSize:props.fontSizeStatistik}}>
                {label}<br/>{value}
            </div>
        }
    }

    return <div className={props.tablet ? "col-6 md:col-6 lg:col-6" :"col-3 md:col-3 lg:col-3"} style={{padding:0}}>
        <div style={{
            textAlign: "center",
            fontSize: 40,
            backgroundColor: "#ac1c27",
            color: "white",
        }}>
            <div style={{width: "100%", fontSize:props.fontSizePlayer, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                {props.name}
            </div>
        </div>
        <div style={{textAlign: "center", fontSize: (props.fontSizePlayer -10), backgroundColor: "#ac1c27", color: "white", paddingBottom: 5}}>
            {props.isSetModus && "Sets: " + props.gewonneneSets + " - "}Legs: {props.gewonneneLegs}
        </div>
        {(props.showSettings.showCheckout && props.checkout !== undefined && props.checkout !== "") && <div style={{
            textAlign: "center",
            fontSize: props.fontSizePlayer - 2,
            backgroundColor: "#ac1c27",
            color: "white",
            padding: 10,
            borderTop: "1px solid black"
        }}>Checkout<br/>{props.checkout}</div>}
        <div className={"statistic_" + props.showSettings.fontWeight} style={{display:"flex", flexWrap:"wrap", color:"white", justifyContent:"center"}}>
            {props.showSettings.showAverage && kachel("Average", props.average)}
            {props.showSettings.showAverageFirst9 && kachel("First 9", props.averageFirst9)}
            {props.showSettings.showAverageLeg && kachel("Leg Avg.", props.averageLeg)}
            {props.showSettings.show100Plus && kachel("100+", props.count100Plus)}
            {props.showSettings.show140Plus && kachel("140+", props.count140Plus)}
            {props.showSettings.show171 && kachel("171", props.count171er)}
            {props.showSettings.show180 && kachel("180", props.count180er)}
            {props.showSettings.showBestLeg && kachel("Best", props.bestLeg)}
            {props.showSettings.showWorstLeg && kachel("Worst", props.worstLeg)}
            {props.showSettings.showHighOut && kachel("High Out", props.highOut)}
        </div>
    </div>
}

export function StatistikAreaSmartphone(props) {

    const kachel = (label, value) => {
        return <div className="grid" style={{width:"100%", paddingLeft:30}}><div className="col">{label}</div><div className="col">{value}</div></div>
    }

    let tmpName = props.name;
    if(props.name.length > 17) {
        tmpName = props.name.slice(0, 15) + "...";
    }

    return <div className="col" style={{width: 300}}>
        <div style={{
            textAlign: "center",
            fontSize: 40,
            backgroundColor: "#ac1c27",
            color: "white",
            padding: 5
        }}>
            {tmpName}
        </div>
        {(props.showSettings.showCheckout && props.checkout !== undefined && props.checkout !== "") && <div style={{
            textAlign: "center",
            fontSize: 40,
            backgroundColor: "#ac1c27",
            color: "white",
            padding: 10,
            borderTop: "1px solid black"
        }}>Checkout<br/>{props.checkout}</div>}
        <div style={{display:"flex", flexWrap:"wrap", color:"white"}}>
            {props.showSettings.showAverage && kachel("Average", props.average)}
            {props.showSettings.showAverageFirst9 && kachel("First 9", props.averageFirst9)}
            {props.showSettings.showAverageLeg && kachel("Leg Avg.", props.averageLeg)}
            {props.showSettings.show100Plus && kachel("100+", props.count100Plus)}
            {props.showSettings.show140Plus && kachel("140+", props.count140Plus)}
            {props.showSettings.show171 && kachel("171", props.count171er)}
            {props.showSettings.show180 && kachel("180", props.count180er)}
            {props.showSettings.showBestLeg && kachel("Best", props.bestLeg)}
            {props.showSettings.showWorstLeg && kachel("Worst", props.worstLeg)}
            {props.showSettings.showHighOut && kachel("High Out", props.highOut)}
        </div>
    </div>
}

export function PlayerChangeArea({onChange, fontSize, iconFontSize}) {
    return <><div style={{
        width: "100%",
        textAlign: "center",
        fontSize: fontSize !== undefined ? fontSize : 25,
        backgroundColor: "white",
        verticalAlign:"middle",
        display:"flex",
        justifyContent:"center",
        cursor:"pointer"
    }} onClick={() => onChange()}>
        <KeyboardArrowLeftIcon style={{fontSize: iconFontSize !== undefined ? iconFontSize : 40}}/>
        Change Player
        <KeyboardArrowRightIcon style={{fontSize: iconFontSize !== undefined ? iconFontSize : 40}}/>
    </div>
    <hr/>
    </>;
}

export function ModusArea({sets, firsttolegs, bestoflegs, modusid, fontSize}) {
    let modus = getModusById(modusid);
    return <div className="grid" style={{
        width: "100%",
        padding: 0,
        margin: 0,
        textAlign: "center",
        fontSize: fontSize === undefined || fontSize === null ? 25 : fontSize,
    }}>
        <div className="col" style={{backgroundColor: "white"}}>
            {sets > 1 && "set " + sets + " - "}{firsttolegs !== undefined && firsttolegs !== "" ? " first to " + firsttolegs + " legs" : ""}{bestoflegs !== undefined && bestoflegs !== "" ? "best of " + bestoflegs + " legs" : ""} - {modus.bezeichnung}</div>
    </div>;
}

export function ModusAreaSmartphone({sets, firsttolegs, bestoflegs, modusid}) {
    let modus = getModusById(modusid);
    return <div className="grid" style={{
        width: "100%", height: 30,
        padding: 0,
        margin: 0,
        textAlign: "center",
        fontSize: 15,
    }}>
        <div className="col" style={{backgroundColor: "white"}}>
            {sets > 1 && "set " + sets + " - "}{firsttolegs !== undefined && firsttolegs !== "" ? " first to legs " + firsttolegs : ""}{bestoflegs !== undefined && bestoflegs !== "" ? "best of legs " + bestoflegs : ""} - {modus.bezeichnung}</div>
    </div>;
}

export function ScoreHeader(props) {
    return <div className="grid" style={{
        width: "100%",
        padding: 0,
        margin: 0,
        textAlign: "center",
        alignContent:"center",
        fontSize: props.fontSize,
        fontWeight: "bold",
    }}>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
        <div className="col-fixed" style={{width: 80, borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}/>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
    </div>;
}

export function ScoreHeaderSmartphone(props) {
    return <div className="grid" style={{
        width: "100%",
        padding: 0,
        margin: 0,
        marginTop:3,
        textAlign: "center",
        alignContent:"center",
        fontSize: props.fontSize,
        fontWeight: "bold",
    }}>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
        <div className="col-fixed" style={{width: 40, borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}/>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
    </div>;
}
