
export function isOnTheWayToNineDarter(startpunkte, scores) {
    let tmpScores = scores.filter(tmp => tmp.value !== '');
    if( Number(startpunkte) !== 501) {
        return false;
    }
    if( !tmpScores || tmpScores.length === 0 || tmpScores.length > 3) {
        return false;
    }
    if( tmpScores.length === 1) {
        let moeglicheWege = WAYS.filter(tmp => tmp.first === Number(tmpScores[0].value));
        if( moeglicheWege.length === 0) {
            return false;
        }
    }
    if( tmpScores.length === 2) {
        let moeglicheWege = WAYS.filter(tmp => tmp.first === Number(tmpScores[0].value) && tmp.second === Number(tmpScores[1].value));
        if( moeglicheWege.length === 0) {
            return false;
        }
    }
    if( tmpScores.length === 3) {
        let moeglicheWege = WAYS.filter(tmp => tmp.first === Number(tmpScores[0].value) &&
            tmp.second === Number(tmpScores[1].value) &&
            tmp.third === Number(tmpScores[2].value));
        if( moeglicheWege.length === 0) {
            return false;
        }
    }
    return true;
}

const WAYS = [
    {"first": 180, "second":180, "third": 141},
    {"first": 177, "second":170, "third": 154},
    {"first": 171, "second":170, "third": 160},
    {"first": 165, "second":180, "third": 156},
    {"first": 180, "second":177, "third": 144},
    {"first": 177, "second":168, "third": 156},
    {"first": 171, "second":160, "third": 170},
    {"first": 164, "second":180, "third": 157},
    {"first": 180, "second":174, "third": 147},
    {"first": 177, "second":167, "third": 157},
    {"first": 170, "second":180, "third": 151},
    {"first": 164, "second":177, "third": 160},
    {"first": 180, "second":171, "third": 150},
    {"first": 177, "second":164, "third": 160},
    {"first": 170, "second":177, "third": 154},
    {"first": 164, "second":170, "third": 167},
    {"first": 180, "second":170, "third": 151},
    {"first": 177, "second":160, "third": 164},
    {"first": 170, "second":174, "third": 157},
    {"first": 164, "second":167, "third": 170},
    {"first": 180, "second":168, "third": 153},
    {"first": 177, "second":157, "third": 167},
    {"first": 170, "second":171, "third": 160},
    {"first": 161, "second":180, "third": 160},
    {"first": 180, "second":167, "third": 154},
    {"first": 177, "second":154, "third": 170},
    {"first": 170, "second":170, "third": 161},
    {"first": 161, "second":170, "third": 170},
    {"first": 180, "second":165, "third": 156},
    {"first": 174, "second":180, "third": 147},
    {"first": 170, "second":167, "third": 164},
    {"first": 160, "second":180, "third": 161},
    {"first": 180, "second":164, "third": 157},
    {"first": 174, "second":177, "third": 150},
    {"first": 170, "second":164, "third": 167},
    {"first": 160, "second":177, "third": 164},
    {"first": 180, "second":161, "third": 160},
    {"first": 174, "second":174, "third": 153},
    {"first": 170, "second":161, "third": 170},
    {"first": 160, "second":174, "third": 167},
    {"first": 180, "second":160, "third": 161},
    {"first": 174, "second":171, "third": 156},
    {"first": 168, "second":180, "third": 153},
    {"first": 160, "second":171, "third": 170},
    {"first": 180, "second":157, "third": 164},
    {"first": 174, "second":170, "third": 157},
    {"first": 168, "second":177, "third": 156},
    {"first": 157, "second":180, "third": 164},
    {"first": 180, "second":154, "third": 167},
    {"first": 174, "second":167, "third": 160},
    {"first": 167, "second":180, "third": 154},
    {"first": 157, "second":177, "third": 167},
    {"first": 180, "second":151, "third": 170},
    {"first": 174, "second":160, "third": 167},
    {"first": 167, "second":177, "third": 157},
    {"first": 157, "second":174, "third": 170},
    {"first": 177, "second":180, "third": 144},
    {"first": 174, "second":157, "third": 170},
    {"first": 167, "second":174, "third": 160},
    {"first": 154, "second":180, "third": 167},
    {"first": 177, "second":177, "third": 147},
    {"first": 171, "second":180, "third": 150},
    {"first": 167, "second":170, "third": 164},
    {"first": 154, "second":177, "third": 170},
    {"first": 177, "second":174, "third": 150},
    {"first": 171, "second":177, "third": 153},
    {"first": 167, "second":167, "third": 167},
    {"first": 151, "second":180, "third": 170},
    {"first": 177, "second":171, "third": 153},
    {"first": 171, "second":174, "third": 156},
    {"first": 167, "second":164, "third": 170}
]