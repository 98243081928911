import './settings.css';
import React, {useEffect, useState} from "react";
import {Alert, Button, Snackbar, TextField, ThemeProvider} from "@mui/material";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {SyncOutlined} from "@mui/icons-material";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {getAllDefaultSettingsFromLocalStorage, getFromLocalStorage, resetLocalStorage, saveToLocalStorage} from "../localStorageZugriff";
import FetchUtil from "../util/FetchUtil";
import {isSmartphone} from "../util/GeraeteArtUtil";
import ConfirmDialog from "../util/ConfirmDialog";
import updateLocalStorage from "../update";

export default function SettingsSync() {

    const smartphone = isSmartphone();

    const url = getFromLocalStorage("dh_url");
    const token = getFromLocalStorage("dh_token");
    const boardToken = getFromLocalStorage("dh_board_token");
    const [configOK] = useState(url !== undefined && url !== "" && token !== undefined && token !== "" && boardToken !== undefined && boardToken !== "");

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState ( "");
    const [settings, setSettings] = useState ([]);
    const [selectedId, setSelectedId] = useState (undefined);
    const [showSaveDialog, setShowSaveDialog] = useState (false);
    const [showDeleteDialog, setShowDeleteDialog] = useState (false);
    const [showUebernehmenDialog, setShowUebernehmenDialog] = useState (false);
    const [showResetDialog, setShowResetDialog] = useState (false);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        if (!configOK) {
            return;
        }
        FetchUtil.fetchGet(url + "/settings", token, boardToken,
            json => {
                setSettings(json);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const save = () => {
        if (!configOK) {
            return;
        }
        setErrorMessage(undefined);
        let localStorageSettings = getAllDefaultSettingsFromLocalStorage();
        let data = {id: selectedId, bezeichnung: bezeichnung, settings: localStorageSettings}
        FetchUtil.fetchPost(url + "/saveSettings", token, boardToken,
            data,
            (id) => {
                setSelectedId(id);
                setShowSaveDialog(false);
                setInfoMessage("Erfolgreich gespeichert...");
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowSaveDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowSaveDialog(false);
            });
    }

    const deleteSettings = () => {
        if (!configOK) {
            return;
        }
        setErrorMessage(undefined);
        let data = {id: selectedId}
        FetchUtil.fetchPost(url + "/deleteSettings", token, boardToken,
            data,
            () => {
                setSelectedId(undefined);
                setBezeichnung("");
                setShowDeleteDialog(false);
                setInfoMessage("Erfolgreich gelöscht...");
                ladeDaten();
            },
            responseNotOk => {
                setSelectedId(undefined);
                setShowDeleteDialog(false);
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setSelectedId(undefined);
                setShowDeleteDialog(false);
                setErrorMessage("Fehler: " + error.message);
            });
    }

    function isNumeric(num){
        return !isNaN(num);
    }

    function isBoolean(value){
        return value === "true" || value === "false";
    }

    const reset = () => {
        resetLocalStorage();
        updateLocalStorage();
        setShowResetDialog(false);
    }

    const settingsUebernehmen = () => {
        let tmp = settings.filter(tmp => tmp.id === selectedId)[0];
        let liste = tmp.settings;
        for (const listeElement of liste) {
            if( isNumeric(listeElement.value)) {
                saveToLocalStorage(listeElement.name, Number(listeElement.value));
            } else if( isBoolean(listeElement.value)) {
                saveToLocalStorage(listeElement.name, listeElement.value === "true");
            } else {
                saveToLocalStorage(listeElement.name, listeElement.value);
            }
        }
        setInfoMessage("Einstellungen wurden in den Scorer übernommen.");
        setSelectedId(undefined);
        setShowUebernehmenDialog(false);
    }


    const init = () => {
        setBezeichnung("");
        setSelectedId(undefined);
    }

    const getBezeichnung = () => {
        return settings.filter(tmp => tmp.id === selectedId)[0].bezeichnung;
    }

    const getRows = () => {
        if( settings.length === 0) {
            return <div style={{marginTop:30, fontWeight:"bold"}}>
                Aktuell keine gespeicherten Einstellungen vorhanden.
            </div>
        }
        let rows = [];
        for (const tmpSettings of settings) {
            rows.push(getRow(tmpSettings.id, tmpSettings.bezeichnung));
        }
        return <div style={{marginTop:30}}>
            {rows}
        </div>
    }

    const getRow = (id, bezeichnung) => {
        return <div key={"key_" + id} style={{marginTop: 10}}>
            <DeleteOutlineIcon style={{marginRight: 10, cursor:"pointer"}} onClick={() => {
                setSelectedId(id);
                setShowDeleteDialog(true);
            }}/>
            <SyncOutlined style={{marginRight: 10, cursor:"pointer"}} onClick={() => {
                setSelectedId(id);
                setShowUebernehmenDialog(true);
            }}/>
            <PlaylistAddCheckIcon style={{marginRight: 10, cursor:"pointer"}}  onClick={() => {
                setSelectedId(id);
                setBezeichnung(bezeichnung);
            }}/>
            {bezeichnung}
            <br/>
            <hr style={{borderTop: "dotted 1px"}}/>
        </div>
    }
    return <div>
        <Snackbar open={infoMessage !== undefined} autoHideDuration={5000} onClose={() => setInfoMessage(undefined)}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={() => setInfoMessage(undefined)} severity="info" sx={{width: '100%', fontSize: 30}}>
                {infoMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={errorMessage !== undefined} autoHideDuration={3000} onClose={() => setErrorMessage(undefined)}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{width: '100%', fontSize: smartphone ? 20 : 30}}>
                {errorMessage}
            </Alert>
        </Snackbar>
        <div>
            <h2>Einstellungen Sync</h2>
            <div style={{marginTop: 10}}>Hier kannst du deine Scorer Einstellungen, wie Schriftgrößen usw, auf dem Server von Darthelfer speichern bzw. evtl. vorhandene Einstellungen in den Scorer
                einspielen.
            </div>
            <div style={{marginTop: 10}}>Hintergrund dafür ist, dass du z.B. 10 gleiche Geräte hast und überall die gleichen, optimierte, Einstellungen haben möchtest.</div>
            <div style={{marginTop: 10}}>Achtung! Sofern du eine bestehende Einstellung überschreibst, werden diese Einstellungen nicht automatisch auf evtl. weitere Scorer übertragen. Du musst an
                diesen Scorern das Schema von Hand neu laden und synchronisieren.
            </div>
            {!configOK && <div style={{marginTop: 30, fontSize: 20, color: "red"}}>
                Deine Einstellungen (Token/Board-Token) für eine Verbindung zum Darthelfer Server sind nicht vorhanden.
            </div>
            }
            {configOK && <>
                <div style={{marginTop: 20}}>Vergib eine sprechende Bezeichnung, z.B. 10 Zoll Tablet im Hochformat, damit du die Einstellung auf anderen Geräten finden und zuweisen kannst.</div>
                <div style={{marginTop: 10}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="id-bezeichnung" type="text" label="Bezeichnung" variant="standard"
                                   value={bezeichnung} onChange={(e) => {
                            setBezeichnung(e.target.value);
                        }}/>
                    </ThemeProvider>
                </div>
                <div style={{fontSize: 10}}>
                    {selectedId ? "Bereits gespeichertes Schema!" : "Schema noch nicht am Server gespeichert!"}
                </div>
                <div style={{marginTop: 10}}>
                    <Button variant="contained" onClick={() => {
                        setShowSaveDialog(true);
                    }} style={{height: 40, marginRight: 5}}
                            disabled={bezeichnung === "" || bezeichnung.length < 5}>Einstellungen Speichern</Button>
                    <Button variant="contained" onClick={() => init()} style={{height: 40, marginRight: 5}}
                    >Neu</Button>
                </div>
                {getRows()}
                <div style={{marginTop: 50}}>
                    Legende: <br/>
                    <DeleteOutlineIcon/> Lösche diese Einstellungen vom Server.<br/>
                    <SyncOutlined/> Synchronisiere die Einstellungen vom Server auf dieses Gerät. Schriftgrößen usw. werden übernommen.<br/>
                    <PlaylistAddCheckIcon/> Auswählen, danach kannst du durch speichern diese Einstellung auf dem Server überschreiben.<br/>
                </div>
            </>}
        </div>
        <div style={{marginTop: 50}}>
            <Button variant="contained" onClick={() => {
                setShowResetDialog(true);
            }} style={{height: 40}}>Alle Einstellungen im Scorer zurücksetzen</Button>
        </div>
        {showDeleteDialog &&
            <ConfirmDialog header="Frage..."
                           text="Möchtest du dieses Schema wirklich löschen?"
                           onYes={() => deleteSettings()} onNo={() => {
                setShowDeleteDialog(false);
                setSelectedId(undefined);
            }
            }/>
        }
        {showSaveDialog &&
            <ConfirmDialog header="Frage..."
                           text="Möchtest du wirklich speichern und eventuell vorhandene Einstellungen überschreiben?"
                           onYes={() => save()} onNo={() => {
                setShowSaveDialog(false);
                setSelectedId(undefined);
            }
            }/>
        }
        {showUebernehmenDialog &&
            <ConfirmDialog header="Frage..."
                           text={"Möchtest du die Einstellungen vom Schema '" + getBezeichnung() + "' übernehmen."}
                           onYes={() => settingsUebernehmen()} onNo={() => {
                setShowUebernehmenDialog(false);
                setSelectedId(undefined);
            }
            }/>
        }
        {showResetDialog &&
            <ConfirmDialog header="Frage..."
                           text={<div>
                               <div style={{marginBottom:20}}>Token und Board-Token bleiben erhalten und müssen evtl. von Hand gelöscht werden.</div>
                               <div>Möchtest du alle weiteren Einstellungen im Scorer zurücksetzen?</div>
                           </div>}
                           onYes={() => reset()} onNo={() => {
                setShowResetDialog(false);
            }
            }/>
        }
    </div>
}