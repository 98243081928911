import updateLocalStorage from "./update";

export function saveToLocalStorage(key, value)  {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocalStorage(key)  {
    const savedItem = localStorage.getItem(key);
    if( savedItem === undefined || savedItem === null || savedItem === "undefined" || savedItem === "") {
        return undefined;
    } else {
        return JSON.parse(savedItem);
    }
}

export function getFromLocalStorageWithDefault(key, defaultValue)  {
    const savedItem = localStorage.getItem(key);
    const parsedItem = JSON.parse(savedItem);
    if( parsedItem === undefined || parsedItem === null || parsedItem === "") {
        return defaultValue;
    } else {
        return parsedItem;
    }
}

export function getAllDefaultSettingsFromLocalStorage()  {
    let settings = [];
    Object.keys(localStorage).forEach(function(key){
        if( key.startsWith("dh_default_") || key.startsWith("dh_tablet_tastatur_row_height")) {
            let tmp = getFromLocalStorage(key);
            settings.push({name: key, value: tmp});
        }
    });
    return settings;
}

export function getAllGamesFromLocalStorage()  {
    let games = [];
    Object.keys(localStorage).forEach(function(key){
        if( key.startsWith("dh_game_")) {
            let game = getFromLocalStorage(key);
            games.push(game);
        }
    });
    return games;
}

export function  resetLocalStorage() {
    Object.keys(localStorage).forEach(function(key){
        if( !key.startsWith("dh_token") && !key.startsWith("dh_board_token") &&
            !key.startsWith("dh_board_bezeichnung") && !key.startsWith("dh_autodarts_boardid") &&
            !key.startsWith("dh_url")) {
            localStorage.removeItem(key);
        }
    });
}

export function removeFromLocalStorage(key)  {
    localStorage.removeItem(key);
}
