import './settings.css';
import {TextField, ThemeProvider} from "@mui/material";
import {useEffect, useState} from "react";
import {CustomFontTheme} from "../x01/CustomFontTheme";
import {GameModusComponent} from "../x01/GameModusComponent";
import {StartpunkteComponent} from "../x01/StartpunkteComponent";
import {getFromLocalStorage, saveToLocalStorage} from "../localStorageZugriff";


function SettingsAllgemein() {

    const [player1, setPlayer1] = useState(getFromLocalStorage("dh_default_player1"));
    const [player2, setPlayer2] = useState(getFromLocalStorage("dh_default_player2"));
    const [sets, setSets] = useState(getFromLocalStorage("dh_default_sets"));
    const [firsttolegs, setFirsttolegs] = useState(getFromLocalStorage("dh_default_firsttolegs"));
    const [bestoflegs, setBestoflegs] = useState(getFromLocalStorage("dh_default_bestoflegs"));
    const [startpunkte, setStartpunkte] = useState(getFromLocalStorage("dh_default_startpunkte"));
    const [modusId, setModusId] = useState(getFromLocalStorage("dh_default_modusid"));

    useEffect(() => {
        saveToLocalStorage("dh_default_player1", player1);
        saveToLocalStorage("dh_default_player2", player2);
        saveToLocalStorage("dh_default_sets", Number(sets));
        saveToLocalStorage("dh_default_firsttolegs", firsttolegs && firsttolegs !== "" ? Number(firsttolegs) : null);
        saveToLocalStorage("dh_default_bestoflegs", bestoflegs && bestoflegs !== "" ? Number(bestoflegs) : null);
        saveToLocalStorage("dh_default_startpunkte", Number(startpunkte));
        saveToLocalStorage("dh_default_modusid", modusId);
    },[player1, player2, sets, firsttolegs, bestoflegs, startpunkte, modusId]);

    return <>
        <div style={{fontSize:18}}>Hinweis: Hier werden Vorbelegungen für neue X01 Games gepflegt.</div>
        <div className="grid">
            <div className="col-12 md:col-12 lg:col-4">
                <h2>Spielerdaten</h2>
                <div style={{marginBottom: 20}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="standard-player1" label="Name Spieler 1" variant="standard" value={player1}
                                   onChange={(e) => setPlayer1(e.target.value)}/>
                    </ThemeProvider>
                </div>
                <div style={{marginBottom: 20}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="standard-player2" label="Name Spieler 2" variant="standard" value={player2}
                                   onChange={(e) => setPlayer2(e.target.value)}/>
                    </ThemeProvider>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-4">
                <h2>Sets / Legs</h2>
                <div style={{marginBottom: 20}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="standard-sets" type="number" label="sets" variant="standard"
                                   InputProps={{inputProps: {min: 1}}}
                                   value={sets} onChange={(e) => setSets(e.target.value)}/>
                    </ThemeProvider>
                </div>
                <div style={{marginBottom: 20}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="standard-firsttolegs" type="number" label="first to legs" variant="standard"
                                   InputProps={{inputProps: {min: 1}}}
                                   value={firsttolegs} onChange={(e) => {
                            setFirsttolegs(e.target.value);
                            setBestoflegs("");
                        }}/>
                    </ThemeProvider>
                </div>
                <div style={{marginBottom: 20}}>
                    <ThemeProvider theme={CustomFontTheme}>
                        <TextField id="standard-bestoflegs" type="number" label="best of legs" variant="standard"
                                   InputProps={{inputProps: {min: 1}}}
                                   value={bestoflegs} onChange={(e) => {
                            setBestoflegs(e.target.value);
                            setFirsttolegs("");
                        }}/>
                    </ThemeProvider>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-4">
                <h2>Spielmodus</h2>
                <div style={{marginBottom: 20}}>
                    <StartpunkteComponent startpunkte={startpunkte}
                                          onStartpunkteChanged={(value) => setStartpunkte(value)} theme={CustomFontTheme} showLabel={true}/>
                </div>
                <div style={{marginBottom: 20}}>
                    <GameModusComponent modusId={modusId} onModusChanged={(value) => setModusId(value)} theme={CustomFontTheme} showLabel={true}/>
                </div>
            </div>
        </div>
    </>
}

export default SettingsAllgemein;
