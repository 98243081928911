import React from "react";
import {Button, Dialog, DialogTitle} from "@mui/material";

export default function ConfirmDialog({header, text, onYes, onNo}) {

    return <Dialog onClose={onNo} open={true}>
        <DialogTitle>{header}</DialogTitle>
        <div style={{padding:20}}>
            <div>
                {text}
            </div>
            <div style={{marginTop:20}}>
                <Button variant="contained" onClick={() => onYes()} style={{height: 40, marginRight: 5}}>
                    Ja
                </Button>
                <Button variant="contained" onClick={() => onNo()} style={{height: 40, marginRight: 5}}>
                    Nein
                </Button>
            </div>
        </div>
    </Dialog>
}